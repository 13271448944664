/*=================================================================*/
/*                     BUTTONS
/*=================================================================*/
.btn {
  border-radius: 30px;
  font-family: $fonthead;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  line-height: 1;
  padding: 12px 32px;
  position: relative;
  margin: 7px;

  &:focus {
    box-shadow: none;
  }
}

.btn:focus {
  outline: 0;
}

@keyframes button-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.btn-default {
  color: #fff !important;
  background: $colorpink;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  &:hover {
    color: #fff;
    -webkit-animation-name: button-push;
    animation-name: button-push;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
}

.btn-full {
  text-align: center;
  width: 100%;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 28px 30px;
  font-size: 18px;
  font-weight: 100;
  line-height: 0;
}

.btn-sm {
  padding: 14px 13px;
  font-size: 10px;
  line-height: 0;
}

.btn-xs {
  padding: 10px 18px;
  font-size: 7px;
  line-height: 0;
}

.btn.disabled,
.btn:disabled {
  opacity: 1;
  cursor: not-allowed;
}

.btn-group a {
  [class*='col-'] {
    margin: 0 10px;
    align-items: center;
    width: 100%;
    // position: relative;
  }
}

.btn-layout {
  display: grid;
  justify-content: space-evenly;
}
